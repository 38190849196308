function startLoading() {
    if (!$('.loader').length) {
        $('body').append('<div class="loader"></div>');
    }
}
function stopLoading() {
    if ($('.loader').length) {
        $('.loader').fadeToggle(function() {
            $(this).remove();
        });
    }
}

var mascara = function(val) {
        return val.replace(/\D/g, '').length === 14 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    options = {
        onKeyPress: function(val, e, field, options) {
            field.mask(mascara.apply({}, arguments), options);
        }
    };

var oTable = null;

window.$body = $(document.body);
window.$document = $(document);

/* Brazilian initialisation for the jQuery UI date picker plugin. */
/* Written by Leonildo Costa Silva (leocsilva@gmail.com). */
jQuery(function($) {
    $.datepicker.regional['pt-BR'] = {
        closeText: 'Fechar',
        prevText: '&#x3c;Anterior',
        nextText: 'Pr&oacute;ximo&#x3e;',
        currentText: 'Hoje',
        monthNames: ['Janeiro', 'Fevereiro', 'Mar&ccedil;o', 'Abril', 'Maio', 'Junho',
            'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
        ],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
            'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
        ],
        dayNames: ['Domingo', 'Segunda-feira', 'Ter&ccedil;a-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sabado'],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        weekHeader: 'Sm',
        dateFormat: 'dd/mm/yy',
        firstDay: 0,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ''
    };
    $.datepicker.setDefaults($.datepicker.regional['pt-BR']);
});


$(function () {
    $("input:not(.btn > input):not(.no-icheck)").iCheck({
        checkboxClass: "icheckbox_square-green",
        radioClass: "iradio_square-green",
        increaseArea: "20%" // optional
    });
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $(".colorbox").colorbox({ transition:"fade", width:"95%", height:"95%"});
    $("form").submit(function () {
        $(".box.box-primary").append("<div class='overlay'><i class='fa fa-refresh fa-spin'></i></div>");
    });

    $('.money').maskMoney({
        allowNegative: true,
        thousands: '.',
        decimal: ','
    });

    $(".decimal4").maskMoney({
        allowNegative: true,
        thousands: ',',
        decimal: '.',
        precision: 4
    });

    $('.money_3').mask('0.000.000.000.000,000', {
        reverse: true
    });
    $('.decimal').mask('00,00');
    $('.percent').mask('#00,00', {
        reverse: true
    });

    $('.datepicker').datepicker();

    if (!Modernizr.inputtypes.date) {
        $('input[type=date]').each(function(index, obj) {
            var data = $(obj).val();
            if (data != '' && (data.indexOf('-') > 1)) {
                var ano = data.substring(0, 4);
                var mes = data.substring(5, 7);
                var dia = data.substring(8, 10);
                var data_formatada = dia + '/' + mes + '/' + ano;

                $(obj).val(data_formatada);
            }
        });

        $('input[type=date]')
            .attr('type', 'text')
            .attr('readonly', 'readonly')
            .datepicker({
                format: 'dd/mm/yyyy',
                language: 'pt-BR'
            });
    }

    $('.data_br').mask('99/99/9999');
    $('.cnpj').mask('99.999.999/9999-99');
    $('.cep').mask('00000-000');
    $('.rg').mask('99.999.999-9');
    $('.cpf').mask('999.999.999-99');
    $('.telefone').mask(mascara, options);

    var popoverOptions = {
        html: true,
        content: function() {
            var content = $(this.dataset.externalContent);
            if (content.length) {
                return content.html();
            }

            return this.dataset.content;
        }
    };

    $('[data-toggle="popover"]').popover(popoverOptions);

    $document.on('draw.dt', function() {
        $('[data-toggle="popover"]').popover(popoverOptions);
        /*if (isMobile()) {
            $('.dataTable td > form .btn-group')
                .removeClass('btn-group')
                .find('.btn')
                .removeClass('btn-xs')
                .addClass('btn-sm');
        }*/
    });

    $('.htmleditor').summernote({
        toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'italic', 'underline', 'clear']],
            ['fontsize', ['fontsize']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['height', ['height']],
            ['table', ['table']],
            ['insert', ['link', 'hr', 'picture']],
            ['view', ['fullscreen', 'codeview']],
            ['help', ['help']]
        ],
        lang: 'pt-BR',
        cleaner: {
            notTime: 2400, // Time to display Notifications.
            action: 'both', // both|button|paste 'button' only cleans via toolbar button, 'paste' only clean when pasting content, both does both options.
            newline: '<br>', // Summernote's default is to use '<p><br></p>'
            notStyle: 'position:absolute;top:0;left:0;right:0', // Position of Notification
            // icon:'<i class="note-icon">[Your Button]</i>'
            keepHtml: false, //Remove all Html formats
            keepClasses: false, //Remove Classes
            badTags: ['style', 'script', 'applet', 'embed', 'noframes', 'noscript', 'html'], //Remove full tags with contents
            badAttributes: ['style', 'start'] //Remove attributes from remaining tags
        }
    });

    $(".select2").select2({
        theme:'bootstrap',
        allowClear: true,
        placeholder:"-",
        language: "pt-BR",
        width: '100%'
    });



});

function stateValuesOnFilters(oSettings) {
    var cols = oSettings.aoPreSearchCols;
    for (var i = 0; i < cols.length; i++) {
        var value = cols[i].sSearch;
        if (value.length > 0) {
            $($("tfoot th")[i]).find('input:not([type="checkbox"])').val(value);
            $($("tfoot th")[i]).find('select').val(value);
            $($("tfoot th")[i]).find('select').trigger('change');
        }
    }
}

//CEP
function buscacep(valor){
    if(valor.length==9){
        startLoading();
        valor = valor.replace('-','');
        $.ajax({
            url: '/buscacep/'+valor,
            dataType: 'json',
            crossDomain:true
        })
            .done(function(json) {
                stopLoading();
                if(json.cidade){
                    logradouro = json.logradouro;

                    $('input[name="logradouro"]').val(logradouro.trim());
                    $('input[name="uf"]').val(json.uf);
                    $('input[name="cidade"]').val(json.cidade);
//                                $('input[name="bairro"]').val(json.bairro);
//                                $('input[name="cidade_id"]').val(json.cidade);
                    $('input[name="numero"]').focus();
                }else{
                    swal('CEP não encontrado');
                }
            })
            .fail(function() {
                stopLoading();
                alert('CEP não encontrado!');
            });
    }
}

function validaDocumento(numero, qual, cliente_id) {
    if(numero !=''){
        if(!qual){
            swal('Tipo de documento não informado!','','error');
            $('#cpfcnpj').val('');
            return false;
        }
        $.ajax({
            url: "/clientes/valida/documento",
            data: {
                numero: numero,
                documento_tipo_id: qual,
                id: cliente_id
            }
        }).done(function(retorno) {
            // console.log(retorno);
        }).fail(function(retorno) {
            if(retorno.responseJSON.erro){
                swal({
                        title: retorno.responseJSON.erro,
                        text: "",
                        type: "error",
                        showCancelButton: false,
                        confirmButtonText: "Ok",
                        closeOnConfirm: false
                    },
                    function(){
                        swal.close();
                        $('#cpfcnpj').val('');
                        $('#cpfcnpj').focus();
                    });
            }else {
                var numero = $('#cpfcnpj').val();
                var resposta = !numero.length ? 'Nulo' : 'Inválido';

                swal({
                        title: 'Número ' + resposta,
                        text: "Este registro não será salvo enquanto o mesmo não for um número válido",
                        type: "error",
                        showCancelButton: false,
                        confirmButtonText: "Ok",
                        closeOnConfirm: true
                    },
                    function(){
                        swal.close();
                        $('#cpfcnpj').val('');
                        $('#cpfcnpj').focus();
                    });
            }
        });
    }

}

// Manter barra lateral fechada ou aberta conforme escolha do usuário
$.AdminLTESidebarTweak = {};

$.AdminLTESidebarTweak.options = {
    EnableRemember: true,
    NoTransitionAfterReload: true
    //Removes the transition after page reload.
};

$(function () {
    "use strict";

    $("body").on("collapsed.pushMenu", function() {
        if($.AdminLTESidebarTweak.options.EnableRemember) {
            localStorage.setItem("toggleState", "closed");
        }
    });

    $("body").on("expanded.pushMenu", function() {
        if($.AdminLTESidebarTweak.options.EnableRemember) {
            localStorage.setItem("toggleState", "opened");
        }
    });

    if ($.AdminLTESidebarTweak.options.EnableRemember) {
        var toggleState = localStorage.getItem("toggleState");
        if (toggleState == 'closed'){
            if ($.AdminLTESidebarTweak.options.NoTransitionAfterReload) {
                $("body").addClass('sidebar-collapse hold-transition').delay(100).queue(function() {
                    $(this).removeClass('hold-transition');
                });
            } else {
                $("body").addClass('sidebar-collapse');
            }
        }
    }
});

function clearFilters(){
    $('.headFilter').val('');
    $('.headFilter').trigger('change');
    $('#dataTableBuilder').DataTable().search("").draw();
}

function icheck(selector) {
    return $(selector).iCheck({
        checkboxClass: 'icheckbox_square-green',
        radioClass: 'iradio_square-green'
    });
}